/*

TemplateMo 563 SEO Dream

https://templatemo.com/tm-563-seo-dream

/
/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/*
---------------------------------------------
font & reset css
---------------------------------------------
*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
/*
---------------------------------------------
reset
---------------------------------------------
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 50%;
}

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background-color: #fff;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  margin-bottom: 0;
}

p {
  font-size: 15px;
  line-height: 30px;
  color: #2a2a2a;
}

img {
  width: 100%;
  overflow: hidden;
}

/*
---------------------------------------------
global styles
---------------------------------------------
*/
html,
body {
  background: #fff;
  font-family: "Open Sans", sans-serif;
}

::selection {
  background: #03a4ed;
  color: #fff;
}

::-moz-selection {
  background: #03a4ed;
  color: #fff;
}

@media (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.page-section {
  margin-top: 120px;
}

.section-heading {
  margin-bottom: 60px;
  text-align: center;
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.section-heading h6 {
  font-size: 24px;
  font-weight: 700;
  color: #33ccc5;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.section-heading h2 {
  font-size: 30px;
  text-transform: capitalize;
  color: #2a2a2a;
  font-weight: 800;
  position: relative;
  z-index: 2;
  line-height: 45px;
}

.section-heading h2 em {
  font-style: normal;
  color: #33ccc5;
}

.section-heading h2 span {
  color: #088ec3;
}

.main-header-button {
  display: inline-block;
  background-color: #fdcb6e !important;
  font-size: 15px;
  font-weight: bold !important;
  color: #000 !important;
  text-transform: capitalize !important;
  padding: 0.5rem 1rem !important;
  margin-top: 10px !important;
  border-radius: 23px !important;
  letter-spacing: 0.25px !important;
  border-bottom: 0px solid #fff !important;
  transition: all 0.3s !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.main-header-button:hover {
  background-color: #fff !important;
  color: #4771cb !important;
  border-bottom: 0px solid #4771cb !important;
}

.main-blue-button a {
  display: inline-block;
  background-color: #fdcb6e;
  font-size: 15px;
  font-weight: bold;
  color: #000 !important;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  margin-top: 10px;
  border-radius: 23px;
  letter-spacing: 0.25px;
  border-bottom: 2px solid #fff !important;
  transition: all 0.3s;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.main-blue-button :hover {
  background-color: #fff !important;
  color: #4771cb !important;
  border-bottom: 2px solid #4771cb !important;
}

.main-green-button a {
  display: inline-block;
  background-color: #33ccc5;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
  border-bottom: 2px solid #4771cb !important;
  transition: all 0.3s;
}

.main-green-button a:hover {
  background-color: #4771cb !important;
  color: #fff !important;
  border-bottom: 2px solid #33ccc5 !important;
}

.main-banner-button {
  display: inline-block;
  background-color: #33ccc5 !important;
  font-size: 15px;
  font-weight: bold !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding: 0.5rem 1rem !important;
  margin-top: 10px !important;
  border-radius: 23px !important;
  letter-spacing: 0.25px !important;
  border-bottom: 0px solid #fff !important;
  transition: all 0.3s !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.main-banner-button:hover {
  background-color: #fff !important;
  color: #4771cb !important;
  border-bottom: 0px solid #4771cb !important;
}

/*
---------------------------------------------
Modal Simulator
---------------------------------------------
*/

.modal-header {
  border: 0 solid;
}

.modal-content {
  width: 800px !important;
  border-radius: 20px;
}

.modal-body {
  margin: 20px;
  text-align: justify;
}

.modal-footer {
  background-color: #33ccc5 !important;
  border-radius: 0 0 20px 20px;
}

.form-check {
  padding-top: 1em;
}

.sim-btn-cancel {
  display: inline-block;
  background-color: #fdcb6e !important;
  font-size: 15px;
  font-weight: bold !important;
  color: #000 !important;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  border-radius: 23px !important;
  letter-spacing: 0.25px;
  border: 1px solid #fdcb6e !important;
  transition: all 0.3s;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.sim-btn-cancel:hover {
  background-color: #fff !important;
  color: #4771cb !important;
  border: 1px solid #4771cb !important;
}

.sim-btn-save {
  display: inline-block;
  background-color: #4771cb !important;
  font-size: 15px;
  font-weight: bold !important;
  color: #fff !important;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  margin-top: 10px;
  border-radius: 23px !important;
  letter-spacing: 0.25px;
  border-bottom: 0px solid #fff !important;
  transition: all 0.3s;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.sim-btn-save:hover {
  background-color: #fff !important;
  color: #4771cb !important;
  border-bottom: 10px solid #4771cb !important;
}

button[type="submit"] {
  display: inline-block;
  background-color: #33ccc5 !important;
  font-size: 15px;
  font-weight: bold !important;
  color: #fff !important;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  margin-top: 10px;
  border-radius: 23px !important;
  letter-spacing: 0.25px;
  border: 1px solid #33ccc5 !important;
  transition: all 0.3s;
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: 30%;
  margin-left: 35%;
  margin-right: 35%;
  max-width: 200px;
}
button[type="submit"]:hover {
  background-color: #fff !important;
  color: #4771cb !important;
  border: 1px solid #4771cb !important;
}
/*
---------------------------------------------
header
---------------------------------------------
*/

.background-header {
  background-color: #33ccc5 !important;
  height: 80px !important;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
}

.background-header .logo,
.background-header .main-nav .nav-AL li a {
  color: #fff;
}

.background-header .main-nav .nav-AL li:hover a {
  color: #33ccc5;
}

.background-header .nav-AL li a.active {
  position: relative;
  color: #fff;
}

.background-header .nav-AL li a.active:after {
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #4771cb;
  content: "";
  left: 50%;
  bottom: 0;
  transform: translateX(-10px);
}

.header-area {
  background-color: #33ccc5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 100px;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo h4 {
  font-size: 24px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
  line-height: 100px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo h4 img {
  max-width: 60px;
  margin-top: -20px;
  margin-left: 5px;
}

.background-header .main-nav .logo h4 {
  line-height: 80px;
}

.background-header .main-nav .nav-AL {
  margin-top: 20px !important;
}

.header-area .main-nav .nav-AL {
  float: right;
  margin-top: 30px;
  margin-right: 0;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.navbar-light .navbar-toggler {
  border: 0px;
}

.header-area .main-nav .nav-AL li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav-AL li:last-child {
  padding-right: 0;
  padding-left: 40px;
}

.header-area .main-nav .nav-AL li:last-child a,
.background-header .main-nav .nav-AL li:last-child a {
  padding: 0 20px;
  font-weight: 400;
}

.header-area .main-nav .nav-AL li:last-child a:hover,
.background-header .main-nav .nav-AL li:last-child a:hover {
  color: #4771cb !important;
}

.container {
  max-width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row {
  position: relative;
  z-index: 1;
}
.AL-logo {
  background-image: url("assets/images/alaportage_noir.png");
  width: 200px !important;
  height: 23px;
  background-size: contain;
  margin-right: 75px;
  margin-left: 20px;
}
.section-AL {
  display: block;
  font-weight: bold;
  font-size: 15px;
  color: #000 !important;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.header-area .main-nav .nav-AL a {
  display: block;
  font-weight: bold;
  font-size: 15px;
  color: #000;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav-AL li:hover a,
.header-area .main-nav .nav-AL li a.active {
  color: #fff !important;
}

.background-header .main-nav .nav-AL li:hover a,
.background-header .main-nav .nav-AL li a.active {
  color: #fff !important;
  opacity: 1;
}

.header-area .main-nav .nav-AL li.submenu {
  position: relative;
  padding-right: 30px;
}

.header-area .main-nav .nav-AL li.submenu:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #2a2a2a;
  position: absolute;
  right: 18px;
  top: 12px;
}

.background-header .main-nav .nav-AL li.submenu:after {
  color: #2a2a2a;
}

.header-area .main-nav .nav-AL li.submenu ul {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 50px;
  opacity: 0;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.header-area .main-nav .nav-AL li.submenu ul li {
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

.header-area .main-nav .nav-AL li.submenu ul li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a !important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav-AL li.submenu ul li a:hover {
  background: #fff;
  color: #33ccc5 !important;
  padding-left: 25px;
}

.header-area .main-nav .nav-AL li.submenu ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav-AL li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #fff;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area .nav {
  margin-top: 30px;
}

.header-area.header-sticky .nav-AL li a.active {
  color: #33ccc5;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav-AL li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav .nav-AL li:last-child,
  .background-header .main-nav .nav-AL li:last-child {
    display: none;
  }
  .header-area .main-nav .nav-AL li:nth-child(6),
  .background-header .main-nav .nav-AL li:nth-child(6) {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .background-header .main-nav .nav-AL {
    margin-top: 80px !important;
  }
  .header-area .main-nav .logo {
    color: #1e1e1e;
  }
  .header-area.header-sticky .nav-AL li a:hover,
  .header-area.header-sticky .nav-AL li a.active {
    color: #33ccc5 !important;
    opacity: 1;
  }
  .header-area.header-sticky .nav-AL li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: #33ccc5;
    padding: 0 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav-AL {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0;
  }
  .background-header .nav-AL {
    margin-top: 80px;
  }
  .header-area .main-nav .nav-AL li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav-AL {
    margin-top: 100px;
  }
  .header-area .main-nav .nav-AL li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .header-area .main-nav .nav-AL li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0 !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav-AL li a:hover {
    background: #eee !important;
    color: #33ccc5 !important;
  }
  .header-area .main-nav .nav-AL li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0;
    width: 100%;
    box-shadow: none;
    height: 0;
  }
  .header-area .main-nav .nav-AL li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav-AL li.submenu ul li a:hover:before {
    width: 0;
  }
  .header-area .main-nav .nav-AL li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav-AL li.submenu:after {
    color: #3b566e;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav-AL li.submenu:hover ul,
  .header-area .main-nav .nav-AL li.submenu:focus ul {
    height: 0;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav-AL {
    display: flex !important;
  }
}

/*
---------------------------------------------
preloader
---------------------------------------------
*/

.js-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.js-preloader.loaded {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@-webkit-keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@-webkit-keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

.preloader-inner {
  position: relative;
  width: 142px;
  height: 40px;
  background: #fff;
}

.preloader-inner .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #33ccc5;
  border-radius: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation: dot 2.8s infinite;
  animation: dot 2.8s infinite;
}

.preloader-inner .dots {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  -webkit-animation: dots 2.8s infinite;
  animation: dots 2.8s infinite;
}

.preloader-inner .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  background: #33ccc5;
  border-radius: 50%;
}

/*
---------------------------------------------
Banner Style
---------------------------------------------
*/

.main-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 70px 0 10px 0;
  position: relative;
  overflow: hidden;
}

.main-banner:after {
  content: "";
  background-image: url(assets/images/banner-dec-left.png);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 80px;
  width: 212px;
  height: 653px;
}

.main-banner:before {
  content: "";
  background-image: url("assets/images/banner-dec-right.png");
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 80px;
  width: 1159px;
  height: 797px;
}

.main-banner .left-content {
  margin-right: 125px;
  margin-left: 45px;
  margin-top: 150px;
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.main-banner .left-content p {
  color: #fff;
}

.main-banner .left-content h6 {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  color: #2a2a2a;
  margin-bottom: 8px;
}

.main-banner .left-content h4 {
  z-index: 2;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  color: #2a2a2a;
}

.main-banner .left-content h2 {
  border-top: 0 solid #eee;
  padding-top: 40px;
  margin-bottom: 45px;
  font-size: 48px;
  font-weight: 800;
  color: #2a2a2a;
  line-height: 72px;
  background: #fa9d4d;
  background: -webkit-linear-gradient(to right, #fa9d4d 0%, #ff4f6b 100%);
  background: -moz-linear-gradient(to right, #fa9d4d 0%, #ff4f6b 100%);
  background: linear-gradient(to right, #fa9d4d 0%, #ff4f6b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-banner .right-image {
  z-index: 2;
  width: auto !important;
  height: 380px !important;

  padding-top: 60px;
  margin-right: 0px;
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

/*
---------------------------------------------
Features Style
---------------------------------------------
*/

#features {
  padding-top: 130px;
  width: 95%;
  text-align: center;
}

.features .features-content {
  z-index: 2;
  position: relative;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.features-item {
  text-align: center;
  padding: 30px;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    105deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  height: 459px;
}

.features-item:hover {
  background: rgb(51, 204, 197);
  background: linear-gradient(
    105deg,
    rgba(51, 204, 197, 1) 0%,
    rgba(8, 141, 195, 1) 100%
  );
}

.features-item:hover h4 {
  color: #fff;
}

.features-item:hover p {
  color: #fff;
}

.features-item:hover .line-dec {
  background-color: rgba(255, 255, 255, 0.3);
}

.first-feature:hover .icon {
  background-image: url(assets/images/01_formations.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.second-feature:hover .icon {
  background-image: url(assets/images/02_Boost_Profil.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.thrid-feature:hover .icon {
  background-image: url(assets/images/03_mission.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.features-item .number h6 {
  background-repeat: no-repeat;
  width: 110px;
  height: 69px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  line-height: 69px;
  margin-top: -65px;
  margin-bottom: 30px;
}

.features-item .first-number h6 {
  background-image: url(assets/images/features-number-01.png);
}

.features-item .second-number h6 {
  background-image: url(assets/images/features-number-02.png);
}

.features-item .third-number h6 {
  background-image: url(assets/images/features-number-03.png);
}

.features-item .fourth-number h6 {
  background-image: url(assets/images/features-number-04.png);
}

.first-feature .icon {
  background-image: url(assets/images/01_formations.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.first-feature {
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
}
.second-feature .icon {
  background-image: url(assets/images/02_Boost_Profil.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.second-feature {
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.third-feature .icon {
  background-image: url(assets/images/03_mission.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.third-feature {
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.features-item .icon {
  width: 100%;
  height: 20%;
  margin: 0 auto;
  transition: all 0.5s;
}

.features-item h4 {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #2a2a2a;
  position: relative;
  transition: all 0.5s;
}

.features-item .line-dec {
  width: 100px;
  height: 2px;
  background-color: rgba(51, 204, 197, 0.3);
  margin: 30px auto;
  transition: all 0.5s;
}

.features-item p {
  text-align: center;
  position: relative;
  margin-top: 0;
  transition: all 0.5s;
}

.skills-content {
  position: relative;
  z-index: 1;
  margin-top: -50px;
  background-color: #f5f5f5;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 110px 0 50px 0;
}

.skill-item {
  text-align: center;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #ddd;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 5px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #fd6a54;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.progress .progress-value {
  text-align: center;
  color: #2a2a2a;
  display: flex;
  width: 100%;
  border-radius: 50%;
  font-size: 32px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 700;
}
.progress .progress-value div {
  margin-top: 10px;
}
.progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

/* This for loop creates the  necessary css animation names
Due to the split circle of progress-left and progress right, we must use the animations on each side.
*/
.progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
}
.progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
}
.progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-3 1.5s linear forwards;
}
.progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-4 1.5s linear forwards;
}
.progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-1 1.5s linear forwards 1.5s;
}

.progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.5s;
}

.progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-3 1.5s linear forwards 1.5s;
}

.progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-4 1.5s linear forwards 1.5s;
}

.progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-5 1.5s linear forwards 1.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}
.progress {
  margin-bottom: 1em;
}

#network {
  margin-top: 100px;
  width: 95%;
}

.network .left-image {
  margin-right: 10%;
  margin-left: 10%;
  background: url("assets/images/AL-network.png") no-repeat;
  text-align: right;
  position: relative;
  z-index: 2;
  width: 600px;
  height: 600px;
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.network .wording {
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
/*
---------------------------------------------
About Us Style
---------------------------------------------
*/

#about {
  padding-top: 130px;
  width: 95%;
}

.about-us .section-heading {
  margin-bottom: 40px;
}

.about-us .left-image {
  margin-right: 45px;
  background: url("assets/images/AL-welcome.svg") no-repeat;
  text-align: right;
  position: relative;
  z-index: 2;
  width: 600px;
  height: 600px;
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.about-us .right-wording {
  visibility: visible;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.about-us .about-item h4 {
  font-size: 38px;
  font-weight: 800;
  color: #088ec3;
}

.about-us .about-item h6 {
  font-size: 16px;
  margin-top: 5px;
  font-weight: 400;
  color: #2a2a2a;
  text-transform: capitalize;
}

.about-us p {
  margin: 40px 0;
}

/*
--------------------------------------------
Accordion Style
--------------------------------------------
*/
.collapse:not(.show) {
  display: none;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  border-radius: 0;
}

.btn h6 {
  font-size: 15px;
  font-weight: 700;
  color: #33ccc5;
  text-transform: uppercase;
}

.faq-content {
  padding: 30px;
  z-index: 2;
  position: relative;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card {
  overflow: hidden;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.Customers {
  display: inline-flex;
}
/*
---------------------------------------------
Services Style
---------------------------------------------
*/

.img-assurancepro {
  background-image: url("assets/images/assurance.svg");
  background-size: contain;
}

.img-optimrevenue {
  background-image: url("assets/images/optim_revenue.svg");
  background-size: contain;
}
.img-garantie {
  background-image: url("assets/images/garantie.svg");
  background-size: contain;
}
.img-fraispro {
  background-image: url("assets/images/frais-pro.svg");
  background-size: contain;
}
.img-mutuelle {
  background-image: url("assets/images/mutuelle.svg");
  background-size: contain;
}

.img-svcadmdedie {
  background-image: url("assets/images/service-dedie.svg");
  background-size: contain;
}

.img-contratcadre {
  background-image: url("assets/images/contrat-cadre.svg");
  background-size: contain;
}

.img-aidejuridique {
  background-image: url("assets/images/aide-juridique.svg");
  background-size: contain;
}

.img-expatriation {
  background-image: url("assets/images/expatriation.svg");
  background-size: contain;
}

.img-admsimplify {
  background-image: url("assets/images/adm-simplify.svg");
  background-size: contain;
}
.img-catalogue {
  background-image: url("assets/images/catalogue.svg");
  background-size: contain;
}

.service-item .icon {
  margin-top: 10px;
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #f7f7f7;
  border-radius: 50%;
}

.service-item .icon img {
  width: 38px;
  height: 38px;
}

.our-services {
  padding-top: 120px;
  width: 95%;
}

.our-services .section-heading {
  text-align: center;
  margin-left: 75px;
  margin-right: 75px;
}

.our-services .container-fluid {
  padding: 0 80px;
}

.service-item {
  padding: 15px 30px;
  border: 2px solid #f7f7f7;
  border-radius: 5px;
  transition: all 0.3s;
  margin-bottom: 30px;
  height: 125px;
}

.service-item:hover {
  background-color: #fff;
  border: 2px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-item .icon {
  margin-top: 10px;
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #f7f7f7;
  border-radius: 50%;
}

.service-item .icon img {
  width: 38px;
  height: 38px;
}

.service-item h4 {
  font-size: 18px;
  font-weight: 700;
  color: #2a2a2a;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 25px;
}

/*
---------------------------------------------
Portfolio
---------------------------------------------
*/

.our-portfolio {
  padding-top: 100px;
  overflow: hidden;
}

.our-portfolio .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.portfolio-item {
  margin-bottom: 30px;
  border-radius: 50px;
}

.portfolio-item:hover .thumb .hover-content {
  opacity: 1;
  visibility: visible;
}

.portfolio-item .thumb {
  position: relative;
  border-radius: 50px;
}

.portfolio-item .thumb img {
  border-radius: 50px;
  overflow: hidden;
}

.portfolio-item .thumb .hover-content {
  border-radius: 50px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(51, 204, 197);
  background: linear-gradient(
    105deg,
    rgba(51, 204, 197, 1) 0%,
    rgba(8, 141, 195, 1) 100%
  );
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.portfolio-item .thumb .hover-content .inner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.portfolio-item .thumb .hover-content .inner-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
}

.portfolio-item .thumb .hover-content .inner-content span {
  font-size: 15px;
  color: #fff;
}

.our-portfolio .owl-nav {
  display: inline-block !important;
  position: absolute;
  top: -117px;
  right: 15%;
  max-width: 1320px;
}

.our-portfolio .owl-nav .owl-next {
  margin-left: 10px;
}

.our-portfolio .owl-nav span {
  width: 46px;
  height: 46px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  font-size: 30px;
  border: 2px solid #eee;
  border-radius: 50%;
  color: #ddd;
  transition: all 0.5s;
}

.our-portfolio .owl-nav span:hover {
  color: #33ccc5;
  border-color: #33ccc5;
}

/*
---------------------------------------------
contact
---------------------------------------------
*/

.contact-us {
  margin-top: 90px;
  padding: 120px 0;
  background-image: url(assets/images/contact-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  visibility: visible;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.contact-us .section-heading {
  text-align: center !important;
  margin-left: 20px;
  margin-right: 20px;
}

.contact-us .contact-info {
  padding: 40px 30px;
  border: 2px solid #eee;
}

.contact-us .contact-info ul li {
  text-align: center;
  display: block;
  margin-bottom: 30px;
}

.contact-us .contact-info ul li a {
  font-size: 15px;
  color: #afafaf;
  transition: all 0.5s;
}

.contact-us .contact-info ul li:hover a {
  color: #2a2a2a;
}

.contact-us .contact-info ul li:last-child {
  margin-bottom: 0;
}

.email-icon {
  background: url("assets/images/contact-icon-01.png") center no-repeat;

  position: relative;
  z-index: 2;
  height: 50px;
}
.phone-icon {
  background: url("assets/images/contact-icon-02.png") center no-repeat;
  text-align: center;
  position: relative;
  z-index: 2;
  height: 50px;
}
.location-icon {
  background: url("assets/images/contact-icon-03.png") center no-repeat;
  text-align: right;
  position: relative;
  z-index: 2;
  height: 50px;
}
.contact-us .contact-info ul li .icon {
  margin-bottom: 10px;
}

.contact-us .contact-info ul li .icon img {
  max-width: 32px;
}
#contact-form {
  display: inline-block;
}
#contact-info {
  display: inline-block;
}
div#contact {
  position: relative;
  background-color: #fff;
  padding: 60px 80px;
  border-radius: 50px;
  text-align: center;
  width: 95%;
  margin: auto;
}

div#contact input {
  width: 100%;
  height: 46px;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid #efefef;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  color: #2a2a2a;
  padding: 0 20px;
  margin-bottom: 20px;
}

div#contact input::placeholder {
  color: #aaa;
}

div#contact textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 180px;
  min-height: 140px;
  height: 140px;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid #efefef;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  color: #2a2a2a;
  padding: 15px 20px;
  margin-bottom: 20px;
}

div#contact textarea::placeholder {
  color: #aaa;
}

div#contact button {
  display: inline-block;
  background-color: #33ccc5;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  margin-top: 20px;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
  border-bottom: 0px solid #4771cb !important;
  transition: all 0.3s;
  outline: none;
  border: none;
}

div#contact button:hover {
  background-color: #4771cb !important;
  color: #fff !important;
  border-bottom: 0px solid #33ccc5 !important;
}

/*
---------------------------------------------
Footer Style
---------------------------------------------
*/

footer p {
  text-align: center;
  margin: 30px 0;
}

footer p a {
  color: #33ccc5;
  transition: all 0.5s;
}

footer p a:hover {
  color: #4771cb;
}

/*
---------------------------------------------
responsive
---------------------------------------------
*/

@media (max-width: 1200px) {
  .header-area .main-nav .logo h4 {
    font-size: 24px;
  }
  .header-area .main-nav .logo h4 img {
    max-width: 60px;
    margin-left: 0;
  }
  .header-area .main-nav .nav-AL li:last-child {
    padding-left: 20px;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav .logo h4 {
    font-size: 20px;
  }
  .main-banner .left-content {
    margin-right: 0;
  }
  .main-banner {
    text-align: center;
  }
  .main-banner:before {
    display: none;
  }
  .main-banner .right-image {
    margin: 30px auto 0 auto;
    text-align: center;
  }
  .features-item {
    margin-bottom: 45px;
  }
  .last-features-item,
  .last-skill-item {
    margin-bottom: 0 !important;
  }
  .skill-item {
    margin-bottom: 30px;
  }
  .service-item {
    text-align: center;
  }
  .service-item .icon {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .about-us .left-image {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 45px;
  }
  .our-portfolio .owl-nav {
    display: none !important;
  }
  .contact-info {
    margin-top: 60px;
  }
  div#contact {
    padding: 45px;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .logo h4 {
    font-size: 30px;
  }
  .header-area .main-nav .logo h4 img {
    max-width: 60px;
    margin-left: 5px;
  }
  .main-banner .info-stat {
    margin-bottom: 15px;
  }
  .service-item {
    text-align: center;
    padding: 30px;
  }
  .service-item .icon {
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .service-item .right-content {
    display: inline-block;
  }
  .our-portfolio .section-heading,
  .about-us .section-heading,
  .about-us .about-item,
  .about-us p,
  .about-us .main-green-button {
    text-align: center;
  }
  .our-services .section-heading {
    margin-left: 15px;
    margin-right: 15px;
  }
  .about-us .about-item {
    margin-top: 15px;
  }
  div#contact {
    padding: 30px;
  }
  .AL-logo {
    margin-right: 0px;
    margin-left: 20px;
  }
  .modal-content {
    width: 100% !important;
  }
  .modal-body {
    margin: 20px;
    text-align: justify;
  }
  .main-banner .left-content h2 {
    border-top: 0 solid #eee;
    margin-top: 30px;
    padding-top: 20px;
    margin-bottom: 25px;
    font-size: 40px;
    font-weight: 800;
    color: #2a2a2a;
    line-height: 72px;
    background: #fa9d4d;
    background: linear-gradient(to right, #fa9d4d 0%, #ff4f6b 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .main-banner .left-content {
    margin-top: 50px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .network .left-image {
    margin-left: -15px;
    background-size: 80%;
  }
  .about {
    padding-top: 0px;
  }
  .about-us .right-wording {
    margin-top: -300px;
  }
  .about-us .left-image {
    margin-right: 0px;
    margin-left: -15px;
    margin-bottom: 0px;
    background-size: 80%;
    margin-top: -300px;
  }
  button[type="submit"] {
    width: 50%;
    margin-left: 25%;
    margin-right: 35%;
  }
  .background-video {
    height: 950px !important;
  }
  .navbar-collapse {
    background: #33ccc5;
    border-radius: 5%;
    padding: 10px;
  }
}

.navbar-collapse {
  background: #33ccc5;
}

.player-wrapper {
  height: 780px;
}

.background-video {
  position: relative;
  top: -150px;
  left: 0;
  height: 150%;
  width: 100%;
}
.overlay {
  height: 110%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
.accordion button {
  background: #fff;
  border: 0px;
  display: inline-block;
}
.AccordionDesc {
  background: #fff;
  padding: 5px;
  text-align: justify;
}
.AccordionImg {
  display: inline-block;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #f7f7f7;
  border-radius: 50%;
  vertical-align: middle;
}

.AccordionTitle {
  display: inline-block;
  padding: 0px;
  vertical-align: middle;
  z-index: 2;
  background: #fff;
}
.AccordionTitle h4 {
  font-size: 18px;
  font-weight: 700;
  color: #2a2a2a;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 25px;
}
.card .card-header {
  background-color: #fff;
  padding: 15px 30px;
  border: 0px solid #f7f7f7 !important;
  border-radius: 5px;
  transition: all 0.3s;
  display: contents;
}
.card {
  margin-bottom: 30px;
}
.card-header {
  background-color: #fff;
  border: 0px !important;
  border-radius: 5px;
  height: 125px;
}
.line-dec {
  width: 100px;
  height: 2px;
  background-color: rgba(51, 204, 197, 0.3);
  margin: 30px auto;
  transition: all 0.5s;
}
